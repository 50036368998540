import type { NextApiResponse } from 'next'
import { setCookie } from 'nookies'
import { ILogoAndAvatarVariants } from '../@types/global'
import {
  COOKIE_PATH,
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_CURRENT_TENANT_EXT,
  SESSION_COOKIE_EXT,
  SESSION_COOKIE_IP,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_ROLE_EXT,
  SESSION_COOKIE_TENANTS,
  SESSION_COOKIE_USER,
  SESSION_EXPIRES_AT_COOKIE_NAME
} from '../constants/cookies'
import { removeAttributesFromServer } from '../utilities/common'

export function setSessionCookieClient(
  cookieName: string,
  cookieValue: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH
  }

  setCookie(null, cookieName, cookieValue, options)
}

export function setSessionCookieExtClient(
  cookieName: string,
  cookieValue: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH,
    secure: true,
    sameSite: 'Lax'
  }

  setCookie(null, cookieName, cookieValue, options)
}

export async function saveSessionCookie(
  res: NextApiResponse,
  jsonFormat: {
    token: string
    user?: {
      data?: {
        attributes?: {
          tenants?: {
            data: Array<{ attributes: { id?: string; name: string } }>
          }
          roles?: { data: Array<{ attributes: { id?: string; name: string } }> }
          userTenants?: {
            data?: Array<{ attributes: { userKind: string } }>
          }
        }
      }
    }
    currentTenant?: {
      data: {
        attributes: {
          id?: string
          name: string
          careerSiteSettings?: {
            canonical_url?: string
          }
          logoVariants?: ILogoAndAvatarVariants
          canonicalUrl?: string
          enablingCareerSiteSetting?: boolean
        }
      }
    }
  },
  expiresIn: number,
  extraParams: {
    ip?: string | string[]
  }
) {
  if (extraParams.ip) {
    await setSessionCookie(
      res,
      SESSION_COOKIE_IP,
      String(extraParams.ip),
      expiresIn
    )
  }
  await setSessionCookie(res, SESSION_COOKIE_NAME, jsonFormat.token, expiresIn)
  await setSessionCookieExt(
    res,
    SESSION_COOKIE_EXT,
    jsonFormat.token,
    expiresIn
  )
  await setSessionCookie(
    res,
    SESSION_COOKIE_USER,
    JSON.stringify({
      ...(jsonFormat?.user?.data?.attributes || {}),
      userTenants:
        jsonFormat?.user?.data?.attributes?.userTenants?.data &&
        jsonFormat?.user?.data?.attributes?.userTenants?.data.length > 0
          ? [
              {
                userKind: (jsonFormat?.user?.data?.attributes || {})
                  ?.userTenants?.data?.[0]?.attributes?.userKind
              }
            ]
          : [],
      avatar: '',
      tenants: { data: [] },
      roles: { data: [] },
      currentTenant: {}
    }),
    expiresIn
  )
  await setSessionExpiryCookie(res, SESSION_EXPIRES_AT_COOKIE_NAME, expiresIn)
  const rolesObj = {
    data: removeAttributesFromServer(
      jsonFormat?.user?.data?.attributes?.roles?.data || []
    )
  }
  const currentRoleFilter = rolesObj.data.filter((item) => item?.id)
  const currentRoleName = currentRoleFilter.length
    ? currentRoleFilter?.[0]?.name
    : ''
  await setSessionCookieExt(
    res,
    SESSION_COOKIE_ROLE_EXT,
    JSON.stringify(currentRoleName).toString(),
    expiresIn
  )
  await setSessionCookie(
    res,
    SESSION_COOKIE_TENANTS,
    JSON.stringify({
      data: removeAttributesFromServer(
        jsonFormat?.user?.data?.attributes?.tenants?.data || []
      )
    }).toString(),
    expiresIn
  )
  if (jsonFormat?.currentTenant?.data) {
    const attributes = jsonFormat.currentTenant.data?.attributes
    const obj = {
      ...attributes,
      canonicalUrl: attributes?.careerSiteSettings?.canonical_url,
      logoVariants: {
        medium: {
          url: attributes?.logoVariants?.medium?.url
        },
        thumb: {
          url: attributes?.logoVariants?.thumb?.url
        }
      },
      careerSiteSettings: {
        ...(attributes?.careerSiteSettings
          ? attributes?.careerSiteSettings
          : {
              canonical_url: attributes?.canonicalUrl,
              enablingCareerSiteSetting: attributes?.enablingCareerSiteSetting
            }),

        description: {
          en: '',
          ja: ''
        }
      }
    }

    await setSessionCookie(
      res,
      SESSION_COOKIE_CURRENT_TENANT,
      JSON.stringify(obj).toString(),
      expiresIn
    )
    await setSessionCookieExt(
      res,
      SESSION_COOKIE_CURRENT_TENANT_EXT,
      JSON.stringify(obj).toString(),
      expiresIn
    )
  }
}

function setSessionCookie(
  res: NextApiResponse,
  cookieName: string,
  cookieValue: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH
  }

  // when the session-cookie gets created
  // we store it as an httpOnly cookie (important!)
  setCookie({ res }, cookieName, cookieValue, options)
}

function setSessionCookieExt(
  res: NextApiResponse,
  cookieName: string,
  cookieValue: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH,
    httpOnly: false,
    secure: true,
    sameSite: 'Lax'
  }

  // when the session-cookie gets created
  // we store it as an httpOnly cookie (important!)
  setCookie({ res }, cookieName, cookieValue, options)
}

function setSessionExpiryCookie(
  res: NextApiResponse,
  cookieName: string,
  expiresIn: number
) {
  const options = {
    maxAge: expiresIn,
    path: COOKIE_PATH
  }

  // when the session-cookie gets created
  // we store it as an httpOnly cookie (important!)
  setCookie({ res }, cookieName, expiresIn.toString(), options)
}
